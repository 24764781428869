import React, { useEffect } from 'react';
import { navigate } from "gatsby"
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';

import { BlogPost } from '@app/model';
import { Footer, Header } from '@app/components/template';
import blogBack from '@app/images/blog-back.jpg';
import './Post.scss';
import { Seo } from '@app/components';

interface BlogPostProps {
  pageContext: {
    post: BlogPost;
  };
  location: any;
  data: any;
}

export default ({ location, pageContext }: BlogPostProps) => {
  const intl = useIntl();
  useEffect(() => {
    if (intl.locale !== post.language) {
      navigate(`/${intl.locale}/${post.alternativePath}`);
    }
  });

  const { post } = pageContext;
  const disqusShortname = `taneczny-kalendarz`;
  const disqusConfig = {
    identifier: post.title,
    title: post.title,
    url: 'https://kartazgloszen.pl'
  };

  return (
    <div id="main" className="main-content-wraper">
      <Seo title={`${post.title} | Blog`}
           description={post.description}
           image={post.image.src}
           url={location.pathname}/>
      <div className="main-content-inner Post">
        <Header />

        <section className="breadcrumb-section"
                 style={ {
                   backgroundImage: `url(${ blogBack })`,
                   backgroundSize: 'cover',
                   backgroundPosition: 'center top',
                   backgroundRepeat: 'no-repeat'
                 } }>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center breadcrumb-content">
                  <h2>
                    <FormattedMessage id="blog_title" />
                  </h2>
                  <ul className="breadcrumb">
                    <li>
                      <Link to='/'>
                        <FormattedMessage id="footer_home" />
                      </Link>
                    </li>
                    <li>
                      <Link to='/blog'>
                        <FormattedMessage id="footer_blog" />
                      </Link>
                    </li>
                    <li>{ post.title }</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="blog-details-area bg-secondary ptb-60">
          <div className="blog-details-area-wrap">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2">
                  <div className="blog-single-content-wrap">
                    <div className="blog-single-img">
                      <img src={ post.image.src } alt={ post.title } className="img-responsive" />
                    </div>
                    <h1>{ post.title }</h1>
                    <div className="blog-details-post-meta"><span>{ post.date }</span> - Paweł Radomski</div>
                    <div className="blog-details-text" dangerouslySetInnerHTML={ { __html: post.html } } />

                    <h2>
                      <FormattedMessage id="blog_leave_your_comment" />
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
};
