exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-prices-old-tsx": () => import("./../../../src/pages/prices-old.tsx" /* webpackChunkName: "component---src-pages-prices-old-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-regulations-tsx": () => import("./../../../src/pages/regulations.tsx" /* webpackChunkName: "component---src-pages-regulations-tsx" */),
  "component---src-templates-post-post-tsx": () => import("./../../../src/templates/post/Post.tsx" /* webpackChunkName: "component---src-templates-post-post-tsx" */)
}

